import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";

export enum ConsentType {
  ConfirmationStatement = "CONFIRMATION_STATEMENT",
  ActAsDirector = "ACT_AS_DIRECTOR",
  ActAsShareholder = "ACT_AS_SHAREHOLDER",
  AnnaTerms = "ANNA_TERMS",
}

@Serializable()
export class ConsentData {
  constructor(@JsonProperty({ type: ConsentType, isArray: true }) readonly items: ConsentType[]) {}
}
