import { Loader as AnnaLoader } from "@anna-money/anna-web-ui";
import classNames from "classnames";

import type { FC } from "react";
import styles from "./Loader.module.scss";

interface LoaderProps {
  className?: string;
  size?: "s" | "m" | "l" | "xl" | "xxl";
  align?: "left" | "center";
  inline?: boolean;
}

export const Loader: FC<LoaderProps> = ({ className, size = "m", align = "left", inline = true }) => {
  return (
    <AnnaLoader
      className={classNames(styles.loader, styles[`inline-${inline}`], styles[`align-${align}`], className)}
      size={size}
    />
  );
};
