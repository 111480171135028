export enum CompanyQuestion {
  Name = "COMPANY_NAME",
  // BusinessName = "COMPANY_BUSINESS_NAME",
  PricingPlan = "COMPANY_PRICING_PLAN",
  Jurisdiction = "COMPANY_JURISDICTION",
  Address = "COMPANY_ADDRESS",
  Occupier = "COMPANY_OCCUPIER",
}

export const COMPANY_FIELD_TO_QUESTION = {
  company_name: CompanyQuestion.Name,
};

export const COMPANY_FIELD_TO_PATH: Record<CompanyQuestion, string> = {
  [CompanyQuestion.Name]: "name",
  // [CompanyQuestion.BusinessName]: "business-name",
  [CompanyQuestion.PricingPlan]: "pricing-plan",
  [CompanyQuestion.Jurisdiction]: "jurisdiction",
  [CompanyQuestion.Address]: "address",
  [CompanyQuestion.Occupier]: "occupier",
};
