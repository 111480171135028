import { reaction } from "mobx";
import { type CompanyStore } from "services/company/companyStore";
import { type CompanyAddressStore } from "services/companyAddress/gb/companyAddressStore";
import type { CompanyBusinessActivitiesStore } from "services/companyBusinessActivities/companyBusinessActivitiesStore";
import { type FormationExpressStore } from "services/formationExpress/formationExpressStore";
import { IssueKey, type IssuesStore } from "services/issues/issuesStore";
import { type PricingPlanStore } from "services/pricingPlan/gb/pricingPlanStore";
import { QuestionsStore } from "services/questions/questionsStore";
import { CompanyQuestion, ISSUE_FIELD_TO_QUESTION, QUESTION_TO_PRODUCT } from "./companyConstants";

const INITIAL_QUESTIONS = Object.values(CompanyQuestion);

export class CompanyQuestionsStore extends QuestionsStore<CompanyQuestion> {
  protected _questionsArray = INITIAL_QUESTIONS;
  protected _activeQuestionKey = this._questionsArray[0];
  protected _issueKey = IssueKey.Company;
  protected _issueFieldsToQuestions = ISSUE_FIELD_TO_QUESTION;

  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _companyAddressStore: CompanyAddressStore,
    private readonly _companyBusinessActivitiesStore: CompanyBusinessActivitiesStore,
    private readonly _pricingPlanStore: PricingPlanStore,
    private readonly _formationExpressStore: FormationExpressStore,
    protected readonly _issuesStore: IssuesStore,
  ) {
    super(_issuesStore);

    reaction(
      () => [this._companyStore.company?.pricingPlan, this._pricingPlanStore.pricingPlanProducts],
      this._filterStepsByPricingPlan.bind(this),
    );
  }

  goToRelevantQuestion(): void {
    const { company } = this._companyStore;

    if (!company?.companyName) {
      this.goToQuestion(CompanyQuestion.Name);
      return;
    }

    if (!company.pricingPlan) {
      this.goToQuestion(CompanyQuestion.PricingPlan);
      return;
    }

    if (!this._companyAddressStore.address) {
      if (!this._formationExpressStore.needToShow) {
        this.goToQuestion(CompanyQuestion.Address);
        return;
      }

      this.goToQuestion(CompanyQuestion.SameDayRegistration);
      return;
    }

    if (!company.country) {
      this.goToQuestion(CompanyQuestion.Country);
      return;
    }

    if (!company.natureOfBusiness) {
      this.goToQuestion(CompanyQuestion.Description);
      return;
    }

    if (!this._companyBusinessActivitiesStore.hasActivities) {
      this.goToQuestion(CompanyQuestion.Activities);
      return;
    }

    if (!company.startsTrading) {
      this.goToQuestion(CompanyQuestion.Starting);
      return;
    }

    this.goToQuestion(this.firstQuestionWithIssue || this.lastQuestion);
  }

  protected async getNextQuestionKey(): Promise<CompanyQuestion> {
    const nextQuestionKey = await super.getNextQuestionKey();

    switch (nextQuestionKey) {
      case CompanyQuestion.SameDayRegistration:
        if (!this._formationExpressStore.needToShow) {
          return this._questionsArray[this.activeQuestionIndex + 2];
        }
        break;
    }

    return nextQuestionKey;
  }

  protected async getPreviousQuestionKey(): Promise<CompanyQuestion> {
    const previousQuestionKey = await super.getPreviousQuestionKey();

    switch (previousQuestionKey) {
      case CompanyQuestion.SameDayRegistration:
        if (!this._formationExpressStore.needToShow) {
          return this._questionsArray[this.activeQuestionIndex - 2];
        }
        break;
    }

    return previousQuestionKey;
  }

  private _filterStepsByPricingPlan(): void {
    this._questionsArray = INITIAL_QUESTIONS.filter((question) => {
      const product = QUESTION_TO_PRODUCT[question];

      if (!product || !this._pricingPlanStore.isProductIncluded(product)) {
        return true;
      }

      return false;
    });
  }
}
