import { Interactor } from "@anna-money/anna-web-ui";
import classNames from "classnames";
import { type FC, type HTMLAttributeAnchorTarget, type ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";

import styles from "./Link.module.scss";

type LinkProps = {
  href?: string;
  weight?: "regular" | "semibold";
  className?: string;
  onClick?: () => void;
  target?: HTMLAttributeAnchorTarget;
  children?: ReactNode;
};

export const Link: FC<LinkProps> = ({
  href,
  weight = "regular",
  target,
  className: classNameProp,
  children,
  onClick,
}) => {
  const className = classNames(styles.link, styles[`weight-${weight}`], classNameProp);
  return href ? (
    <RouterLink className={className} to={href} target={target}>
      {children}
    </RouterLink>
  ) : (
    <Interactor
      className={className}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {children}
    </Interactor>
  );
};
