import { FormUpdateMethod } from "helpers/decorators/formUpdateMethod";
import { PricingPlanStore } from "services/pricingPlan/au/pricingPlanStore";
import { type PricingPlanAu } from "services/pricingPlan/pricingPlanTypes";
import { FormError } from "types/errors";
import type { BackendFormValidationError } from "types/form";

export interface PricingPlanFormValues {
  pricingPlan?: PricingPlanAu;
}

export class PricingPlanFormStore {
  constructor(private readonly _pricingPlanStore: PricingPlanStore) {}

  @FormUpdateMethod()
  async onSubmit(values: PricingPlanFormValues): Promise<BackendFormValidationError> {
    if (!values.pricingPlan) {
      throw new FormError("No pricing plan selected");
    }

    await this._pricingPlanStore.updatePricingPlan(values.pricingPlan);
  }
}
