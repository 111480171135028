import { AnnaError } from "@anna-money/anna-web-lib";
import { runInAction } from "mobx";

import { returnQuestionParam } from "auth/authPages";
import { isSomeEnum } from "helpers/enum";
import { type CompanyFormValues } from "helpers/forms/au/companyTypes";
import { type DirectorFormValues } from "helpers/forms/directorTypes";
import { type AnalyticsManager } from "services/analytics/analyticsManager";
import { type CompanyStore } from "services/company/companyStore";
import { type CompanyAddressStore } from "services/companyAddress/au/companyAddressStore";
import { type CompanyProductsStore } from "services/companyProducts/companyProductsStore";
import { type DirectorEntity, type DirectorStore } from "services/director/directorStore";
import { type IncorporationStore } from "services/incorporation/incorporationStore";
import { IncorporationStatus } from "services/incorporation/incorporationTypes";
import { type IssuesStore } from "services/issues/issuesStore";
import { type PaymentMethodsStore } from "services/paymentMethods/paymentMethodsStore";
import { RegisterFormStoreBase } from "services/registerForm/registerFormStoreBase";
import { type CompanyStepEntity, type CompanyStepStore } from "services/stepStore/au/companyStepStore";
import type { UserStore } from "services/user/userStore";
import { FlowType } from "services/user/userTypes";
import { type BackendFormValidationError } from "types/form";
import { type AcnRegisterFormStepsStore } from "./acnRegisterFormStepsStore";
import { AcnFormStep } from "./constants";

export class AcnRegisterFormStore extends RegisterFormStoreBase<AcnFormStep> {
  protected _flowType: FlowType = FlowType.Acn;

  constructor(
    private readonly _directorStore: DirectorStore,
    private readonly _companyStore: CompanyStore,
    private readonly _companyAddressStore: CompanyAddressStore,
    private readonly _companyProductsStore: CompanyProductsStore,
    private readonly _paymentMethodsStore: PaymentMethodsStore,
    protected readonly _userStore: UserStore,
    protected readonly _analyticsManager: AnalyticsManager,
    protected readonly _registerFormStepsStore: AcnRegisterFormStepsStore,
    protected readonly _incorporationStore: IncorporationStore,
    protected readonly _companyIssuesStore: IssuesStore,
    protected readonly _companyStepStore: CompanyStepStore,
  ) {
    super(_registerFormStepsStore, _userStore, _incorporationStore, _companyIssuesStore, _analyticsManager);
  }

  async init(): Promise<void> {
    await super.init();

    try {
      await this._companyStore.init();

      await Promise.all([
        this._companyAddressStore.init(),
        this._directorStore.init(),
        this._companyProductsStore.init(),
        this._companyIssuesStore.init(),
        this._incorporationStore.init(),
        this._paymentMethodsStore.init(),
      ]);

      runInAction(() => {
        this.setStep();

        this.state = "done";
      });
    } catch (error: unknown) {
      this.state = "error";

      this._analyticsManager.event("load-form-error");

      throw new AnnaError(`Form couldn‘t load with the error: ${(error as Error).toString()}`);
    }
  }

  setStep(): void {
    const searchParams = new URLSearchParams(window.location.search);
    const queryStringReturnStep = (searchParams.get(returnQuestionParam) ?? "").split("_")[0];
    const isWizardStep = isSomeEnum(AcnFormStep);

    if (isWizardStep(queryStringReturnStep)) {
      if (queryStringReturnStep === AcnFormStep.BASKET) {
        this._registerFormStepsStore.setStep(AcnFormStep.BASKET);
        return;
      }
    }

    const { incorporation } = this._incorporationStore;

    const isRejectedButAllIssuesResolved =
      incorporation?.status === IncorporationStatus.Rejected && !this._companyIssuesStore.hasIssues();

    if (incorporation?.created && !isRejectedButAllIssuesResolved) {
      this._registerFormStepsStore.setStep(AcnFormStep.COMPLETE);
      return;
    }

    if (this._paymentMethodsStore.hasActivePaymentMethod) {
      this._registerFormStepsStore.setStep(AcnFormStep.CONFIRM);
      return;
    }

    if (this._directorStore.mainDirector) {
      this._registerFormStepsStore.setStep(AcnFormStep.DIRECTOR);
      return;
    }

    if (this._companyStore.company?.stateOfRegistration) {
      this._registerFormStepsStore.setStep(AcnFormStep.COMPANY);
      return;
    }

    this._registerFormStepsStore.setStep(AcnFormStep.BEFORE_STARTED);
  }

  protected async _updateStep(data: unknown, entity?: unknown): Promise<BackendFormValidationError> {
    switch (this._registerFormStepsStore.activeStep?.type) {
      case AcnFormStep.COMPANY:
        return await this._companyStepStore.updateEntity(data as CompanyFormValues, entity as CompanyStepEntity);
      case AcnFormStep.DIRECTOR:
        return await this._directorStore.updateMainDirector(data as DirectorFormValues, entity as DirectorEntity);
      case AcnFormStep.CONFIRM:
        return await this.createIncorporation();
    }
  }
}
