import { type AnalyticsManager } from "services/analytics/analyticsManager";
import { type IssuesStore } from "services/issues/issuesStore";
import { type BasketQuestionsStore } from "services/questions/gb/basketQuestionsStore";
import { type CompanyQuestionsStore } from "services/questions/gb/companyQuestionsStore";
import { type DirectorQuestionsStore } from "services/questions/gb/directorQuestionsStore";
import { type IdentityQuestionsStore } from "services/questions/gb/identityQuestionsStore";
import {
  RegisterFormStep,
  RegisterFormSteps,
  RegisterFormStepsStoreBase,
} from "services/registerForm/registerFormStepsStoreBase";
import { IncoroporationFormStep } from "./constants";

export class IncorporationRegisterFormStepsStore extends RegisterFormStepsStoreBase<IncoroporationFormStep> {
  protected readonly _steps: RegisterFormSteps<IncoroporationFormStep> = new RegisterFormSteps<IncoroporationFormStep>([
    new RegisterFormStep(IncoroporationFormStep.INTRO, "How it works"),
    new RegisterFormStep(IncoroporationFormStep.CONSENT, "Before you get started"),
    new RegisterFormStep(IncoroporationFormStep.COMPANY, "About your business", this._companyQuestionsStore),
    new RegisterFormStep(IncoroporationFormStep.DIRECTOR, "About you (The director)", this._directorQuestionsStore),
    new RegisterFormStep(IncoroporationFormStep.SHARES, "Particulars"),
    new RegisterFormStep(IncoroporationFormStep.IDENTITY, "Confirm identity", this._identityQuestionsStore),
    new RegisterFormStep(IncoroporationFormStep.BASKET, "Your basket", this._basketQuestionsStore),
    new RegisterFormStep(IncoroporationFormStep.CONFIRM, "Confirm and submit"),
    new RegisterFormStep(IncoroporationFormStep.CHARGE, "Confirm and submit"),
    new RegisterFormStep(IncoroporationFormStep.COMPLETE, "Complete"),
  ]);

  constructor(
    private readonly _companyQuestionsStore: CompanyQuestionsStore,
    private readonly _directorQuestionsStore: DirectorQuestionsStore,
    private readonly _identityQuestionsStore: IdentityQuestionsStore,
    private readonly _basketQuestionsStore: BasketQuestionsStore,
    protected readonly _issuesStore: IssuesStore,
    protected readonly _analyticsManager: AnalyticsManager,
  ) {
    super(_issuesStore, _analyticsManager);
  }

  protected _getPreviousStep(): IncoroporationFormStep {
    if (this.activeStep?.type === IncoroporationFormStep.BASKET) {
      return IncoroporationFormStep.SHARES;
    }

    return super._getPreviousStep();
  }
}
