import { delay } from "@anna-money/anna-web-lib";

export async function retry<T>(
  func: () => Promise<T>,
  isRetryable: (e: unknown) => boolean,
  retries: number = 8,
): Promise<T> {
  let trial = 1;
  while (true) {
    try {
      return await func();
    } catch (e) {
      if (!isRetryable(e) || trial >= retries) {
        throw e;
      }
    }
    await delay(2 ** (trial - 1) * 20);
    trial++;
  }
}
