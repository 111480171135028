import { AnnaError } from "@anna-money/anna-web-lib";
import { makeObservable, observable } from "mobx";
import { getFromMapByKey } from "helpers/object";
import { type IssueFieldQuestionMap, type IssueKey, type IssuesStore } from "services/issues/issuesStore";

export abstract class QuestionsStore<T> {
  protected abstract _questionsArray: T[];
  protected abstract _activeQuestionKey: T;
  protected abstract _issueKey: IssueKey | null;
  protected abstract _issueFieldsToQuestions: IssueFieldQuestionMap<T> | null;

  protected constructor(protected readonly _issuesStore: IssuesStore) {
    makeObservable(this, {
      _activeQuestionKey: observable,
    } as any);
  }

  get activeQuestionKey(): T {
    return this._activeQuestionKey;
  }

  private set activeQuestionKey(value: T) {
    this._activeQuestionKey = value;
  }

  get activeQuestionIndex(): number {
    return this._questionsArray.indexOf(this.activeQuestionKey);
  }

  get firstQuestion(): T {
    return this._questionsArray[0];
  }

  get lastQuestion(): T {
    return this._questionsArray.slice(-1)[0];
  }

  get isFirstQuestion(): boolean {
    return this.activeQuestionIndex === 0;
  }

  async isLastQuestion(): Promise<boolean> {
    return this.activeQuestionIndex === this._questionsArray.length - 1;
  }

  get firstQuestionWithIssue(): T | undefined {
    if (!this._issueKey || !this._issueFieldsToQuestions) {
      return;
    }

    const issue = this._issuesStore.getFirstIssueForKey(this._issueKey);

    return getFromMapByKey(this._issueFieldsToQuestions, issue?.field);
  }

  goToQuestion(question: T): void {
    this.activeQuestionKey = question;
  }

  goToRelevantQuestion(): void {
    this.goToQuestion(this.firstQuestion);
  }

  protected async getPreviousQuestionKey(): Promise<T> {
    return this._questionsArray[this.activeQuestionIndex - 1];
  }

  protected async getNextQuestionKey(): Promise<T> {
    return this._questionsArray[this.activeQuestionIndex + 1];
  }

  async goBack(): Promise<void> {
    if (this.isFirstQuestion) {
      throw new AnnaError("Can‘t go back from the first question");
    }

    const prevQuestionKey = await this.getPreviousQuestionKey();

    this.goToQuestion(prevQuestionKey);
  }

  async goNext(): Promise<void> {
    if (await this.isLastQuestion()) {
      throw new AnnaError("Can‘t go forward from the last question");
    }

    const nextQuestionKey = await this.getNextQuestionKey();

    this.goToQuestion(nextQuestionKey);
  }

  hasIssues(): boolean {
    if (!this._issueKey) {
      return false;
    }

    return this._issuesStore.hasIssueForKey(this._issueKey);
  }

  async resoveIssueIfNeeded(): Promise<void> {
    if (!this._issueKey || !this._issueFieldsToQuestions) {
      return;
    }

    const issues = this._issuesStore.getIssuesForKey(this._issueKey);
    for (const issue of issues) {
      const question = getFromMapByKey(this._issueFieldsToQuestions, issue.field);
      if (question === this._activeQuestionKey) {
        await this._issuesStore.resolveIssue(issue.id);
      }
    }
  }
}
