export enum AcnFormStep {
  BEFORE_STARTED = "BEFORE_STARTED",
  WHAT_TO_EXPECT = "WHAT_TO_EXPECT",
  COMPANY = "COMPANY",
  DIRECTOR = "DIRECTOR",
  BASKET = "BASKET",
  CONFIRM = "CONFIRM",
  COMPLETE = "COMPLETE",
}

export const FORM_STEP_TO_PATH: Record<AcnFormStep, string> = {
  [AcnFormStep.BEFORE_STARTED]: "before-started",
  [AcnFormStep.WHAT_TO_EXPECT]: "what-to-expect",
  [AcnFormStep.COMPANY]: "company",
  [AcnFormStep.DIRECTOR]: "director",
  [AcnFormStep.BASKET]: "card-details",
  [AcnFormStep.CONFIRM]: "confirm",
  [AcnFormStep.COMPLETE]: "complete",
};
