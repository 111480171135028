import { replaceSearchParams } from "@anna-money/anna-web-lib";
import { observer } from "mobx-react-lite";
import { type FC } from "react";
import { useHref, useLocation, useNavigate } from "react-router-dom";
import { Loader } from "components/Loader/Loader";
import { useMount } from "helpers/hooks/useMount";
import { useReturnUrl } from "helpers/hooks/useReturnUrl";
import { useSearchParam } from "helpers/hooks/useSearchParam";
import { type BaseServices } from "services";
import { type AuthStore } from "./authStore";

const redirectUrlParam = "redirect";
const redirectUrlParamsKey = "redirect_url_params";
const loginHintParamKey = "auth_login_hint";
const screenTypeParamKey = "auth_screen";
const gclidUrlParam = "gclid";
export const returnQuestionParam = "return_question";

const getAuthCallbackUrl = (currentUrl: URL, authFinishPath: string): URL => {
  const redirectUrl = currentUrl.searchParams.get(redirectUrlParam);
  if (!redirectUrl) {
    throw new Error("No redirect specified");
  }

  const callbackUrl = new URL(currentUrl);
  callbackUrl.pathname = authFinishPath;
  replaceSearchParams(
    callbackUrl,
    new URLSearchParams({
      [redirectUrlParam]: redirectUrl,
    }),
  );

  return callbackUrl;
};

const authenticate = async (authStore: AuthStore, currentUrl: URL): Promise<string> => {
  const redirectUrl = currentUrl.searchParams.get(redirectUrlParam);
  if (!redirectUrl) {
    throw new Error("No redirect specified");
  }

  const callbackUrl = new URL(currentUrl);
  replaceSearchParams(
    callbackUrl,
    new URLSearchParams({
      [redirectUrlParam]: redirectUrl,
    }),
  );

  await authStore.authenticate(currentUrl, callbackUrl);
  if (authStore.state !== "authenticated") {
    throw new Error("Couldn't authenticate");
  }

  return redirectUrl;
};

type AuthCapturePageProps = {
  services: BaseServices;
  redirect: string;
};
/**
 * Captures current (site entry) url and redirects to `AuthStartPage`
 * preserving current url if the user is unauthenticated
 */
export const AuthCapturePage: FC<AuthCapturePageProps> = observer(({ services, redirect }) => {
  const { authStore, dataStorage } = services;
  const navigate = useNavigate();
  const location = useLocation();
  useMount(() => {
    authStore.initialize();
    if (authStore.state !== "authenticated") {
      const authParams = getAuthParams(location.search);
      dataStorage.set(redirectUrlParamsKey, `${location.search}${location.hash}`);
      navigate(
        {
          pathname: redirect,
          search: new URLSearchParams({
            ...authParams,
            [redirectUrlParam]: location.pathname,
          }).toString(),
        },
        { replace: true },
      );
    }
  });

  return <Loader size="l" />;
});

type AuthStartPageProps = {
  services: BaseServices;
  redirect: string;
};

/**
 * Sends user to the authentication service
 */
export const AuthStartPage: FC<AuthStartPageProps> = ({ services, redirect }) => {
  const { authStore } = services;

  const absoluteRedirect = useHref(redirect);
  const callbackUrl = getAuthCallbackUrl(new URL(window.location.toString()), absoluteRedirect);
  const authParams = getAuthParams(window.location.search);
  const loginHint = loginHintParamKey in authParams ? authParams[loginHintParamKey] : undefined;
  const authUrl = authStore.getAuthUrl(callbackUrl, loginHint);

  window.location.replace(authUrl);

  return null;
};

type AuthFinishPageProps = {
  services: BaseServices;
};

/**
 * Finalizes authenticating after coming from authentication service
 */
export const AuthFinishPage: FC<AuthFinishPageProps> = ({ services }) => {
  const navigate = useNavigate();
  const { authStore, dataStorage } = services;
  useMount(async () => {
    const redirectUrlParams = dataStorage.get(redirectUrlParamsKey, String) || "";
    const redirectUrl = await authenticate(authStore, new URL(window.location.toString()));
    navigate(`${redirectUrl}${redirectUrlParams}`, { replace: true });
    dataStorage.remove(redirectUrlParamsKey);
  });

  return <div>Authenticating...</div>;
};

/**
 * Refreshing auth toke via redirect to the auth service
 */
export const AuthTokenRefreshPage: FC<AuthFinishPageProps> = ({ services }) => {
  const { authStore } = services;
  const returnQuestion = useSearchParam(returnQuestionParam);
  const returnUrl = useReturnUrl(returnQuestion, true);
  useMount(async () => {
    const authUrl = authStore.getAuthUrl(new URL(returnUrl));
    authStore.refresh();
    window.location.replace(authUrl);
  });

  return <div>Authenticating...</div>;
};

export function getAuthParams(searchString: string): Record<string, string> {
  const result: Record<string, string> = {};
  const searchParams = new URLSearchParams(searchString);
  const loginHint = searchParams.get(loginHintParamKey);
  const screenType = searchParams.get(screenTypeParamKey);
  const gclid = searchParams.get(gclidUrlParam);

  if (loginHint) {
    result[loginHintParamKey] = loginHint;
  }
  if (screenType) {
    result[screenTypeParamKey] = screenType;
  }
  if (gclid) {
    result[gclidUrlParam] = gclid;
  }

  for (const [key, value] of searchParams.entries()) {
    if (key.toLowerCase().startsWith("utm_")) {
      result[key] = value;
    }
  }

  return result;
}
