import { replaceSpacesToNbsp } from "helpers/string";
import { AddressCreateData, type AddressData, AddressUpdateData } from "services/address/addressTypes";
import { type AddressForm } from "./addressTypes";

export const addressDataToForm = (address?: AddressData): AddressForm | undefined => {
  if (!address) {
    return;
  }

  return {
    premise: address.premise,
    street: address.street,
    town: address.town,
    postcode: address.postcode,
    county: address.county ?? undefined,
    isOwnedByCompany: address.isOwnedByCompany ?? undefined,
    occupierName: address.occupierName ?? undefined,
    occupierDeclaration: address.occupierDeclaration ?? undefined,
    isUsualResidentalAddress: address.isUsualResidentalAddress ?? undefined,
    asicApprovalNumber: address.asicApprovalNumber ?? undefined,
  };
};

export const addressFormToCreateData = (
  addressForm: AddressForm,
  ids: {
    companyId?: string;
    directorIdRegistered?: string;
    directorIdRegisteredPrevious?: string;
  },
): AddressCreateData => {
  return new AddressCreateData(
    addressForm.premise ?? "",
    addressForm.street,
    addressForm.town,
    addressForm.postcode,
    addressForm.county,
    addressForm.isOwnedByCompany,
    addressForm.isOwnedByCompany ? "" : addressForm.occupierName,
    addressForm.isOwnedByCompany ? false : addressForm.occupierDeclaration,
    addressForm.isUsualResidentalAddress,
    addressForm.isUsualResidentalAddress ? "" : addressForm.asicApprovalNumber,
    ids.companyId,
    ids.directorIdRegistered,
    ids.directorIdRegisteredPrevious,
  );
};

export const addressFormToUpdateData = (addressForm: AddressForm): AddressUpdateData => {
  return new AddressUpdateData(
    addressForm.premise ?? "",
    addressForm.street,
    addressForm.town,
    addressForm.postcode,
    addressForm.county,
    addressForm.isOwnedByCompany,
    addressForm.isOwnedByCompany ? "" : addressForm.occupierName,
    addressForm.isOwnedByCompany ? false : addressForm.occupierDeclaration,
    addressForm.isUsualResidentalAddress,
    addressForm.asicApprovalNumber,
  );
};

export const formatAddress = (address?: AddressData): string => {
  if (!address) {
    return "";
  }

  // eslint-disable-next-line max-len
  return `${address.premise} ${address.street} ${address.town} ${replaceSpacesToNbsp(address.postcode)} ${address.country}`;
};
