import { returnQuestionParam } from "auth/authPages";

export function useReturnUrl(returnQuestion: string, returnToRoot: boolean = false): string {
  const returnUrl = new URL(returnToRoot ? window.location.origin : window.location.toString());
  const params = new URLSearchParams(returnUrl.search);
  params.append(returnQuestionParam, returnQuestion);
  returnUrl.search = params.toString();

  return returnUrl.toString();
}
