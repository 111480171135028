import { JsonProperty, type Nullable, Serializable } from "@anna-money/anna-web-lib";

export enum FeatureState {
  Enabled = "ENABLED",
  Disabled = "DISABLED",
}

@Serializable()
export class FeatureSet {
  constructor(
    // formation_poa_openbanking
    @JsonProperty({ type: FeatureState, required: false }) readonly formationPoaOpenbanking: Nullable<FeatureState>,
  ) {}
}

export type Feature = keyof FeatureSet;
