import { AnnaError } from "@anna-money/anna-web-lib";
import { makeAutoObservable } from "mobx";

import { addressFormToCreateData, addressFormToUpdateData } from "helpers/forms/address";
import { type AddressForm } from "helpers/forms/addressTypes";
import { type AddressClient, AddressKey } from "services/address/addressClient";
import { type AddressData } from "services/address/addressTypes";

export enum DirectorAddressKey {
  Main = "main",
  Previous = "previous",
}

type DirectorAddressState = Record<
  string,
  {
    [DirectorAddressKey.Main]: AddressData;
    [DirectorAddressKey.Previous]?: AddressData;
    previousAddressRequired?: boolean;
  }
>;

export class DirectorAddressStore {
  private readonly _state: DirectorAddressState = {};

  constructor(private readonly _addressClient: AddressClient) {
    makeAutoObservable(this);
  }

  tryGetForDirector(
    directorId: string,
    addressKey: DirectorAddressKey = DirectorAddressKey.Main,
  ): AddressData | undefined {
    return this._state[directorId]?.[addressKey];
  }

  getForDirector(directorId: string, addressKey: DirectorAddressKey = DirectorAddressKey.Main): AddressData {
    const address = this.tryGetForDirector(directorId, addressKey);

    if (!address) {
      throw new AnnaError(`Director address not found for directorId: ${directorId}`);
    }

    return address;
  }

  setPreviousAddressRequired(directorId: string, previousAddressRequired: boolean): void {
    this._state[directorId] = {
      ...this._state[directorId],
      previousAddressRequired,
    };
  }

  getPreviousAddressRequired(directorId: string): boolean {
    return this._state[directorId]?.previousAddressRequired ?? false;
  }

  async loadForDirector(directorId: string, addressKey: DirectorAddressKey = DirectorAddressKey.Main): Promise<void> {
    const address = await this._addressClient.getAddress(
      addressKey === DirectorAddressKey.Main ? AddressKey.Director : AddressKey.DirectorPrevious,
      directorId,
    );

    if (address) {
      this._setAddressToState(directorId, addressKey, address);
      if (addressKey === DirectorAddressKey.Previous) {
        this.setPreviousAddressRequired(directorId, true);
      }
    }
  }

  async updateForDirector(
    directorId: string,
    addressForm: AddressForm,
    addressKey: DirectorAddressKey = DirectorAddressKey.Main,
  ): Promise<void> {
    const existedAddress = this.tryGetForDirector(directorId, addressKey);
    let address;

    if (existedAddress) {
      address = await this._addressClient.updateAddress(existedAddress.id, addressFormToUpdateData(addressForm));
    } else {
      const ids =
        addressKey === DirectorAddressKey.Main
          ? { directorIdRegistered: directorId }
          : { directorIdRegisteredPrevious: directorId };
      address = await this._addressClient.createAddress(addressFormToCreateData(addressForm, ids));
    }

    this._setAddressToState(directorId, addressKey, address);
  }

  private _setAddressToState(directorId: string, addressKey: DirectorAddressKey, address: AddressData): void {
    this._state[directorId] = {
      ...this._state[directorId],
      [addressKey]: address,
    };
  }
}
