import { type CompanyData, CompanyNamePostfix, CompanyPricingPlanUpdateData } from "services/company/companyTypes";
import { PricingPlan } from "services/pricingPlan/pricingPlanTypes";
import { SignUpPageStoreBase } from "services/sighUpPage/signUpPageStoreBase";

export const pricingPlanUrlParam = "pricing_plan";

export class SignUpPageStore extends SignUpPageStoreBase {
  protected _defaultPostfix = CompanyNamePostfix.PtyLtdV1;

  protected async _proceedSignUp(): Promise<undefined | CompanyData> {
    const company = await super._proceedSignUp();

    if (company) {
      await this._savePricingPlan(this._extractSearchParams(), company);
    }

    return company;
  }

  private async _savePricingPlan(searchParams: URLSearchParams, company: CompanyData): Promise<void> {
    const pricingPlan = searchParams.get(pricingPlanUrlParam); // 'basic' or 'anna_one'

    if (!pricingPlan) {
      return;
    }

    switch (pricingPlan) {
      case "basic":
        await this._companyClient.updateCompany(company.id, new CompanyPricingPlanUpdateData(PricingPlan.EasyCompany));
        break;
      case "anna_one":
        await this._companyClient.updateCompany(company.id, new CompanyPricingPlanUpdateData(PricingPlan.AnnaOne));
        break;
    }
  }
}
