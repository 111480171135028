import { makeAutoObservable } from "mobx";
import { type CompanyProductsStore } from "services/companyProducts/companyProductsStore";
import { CompanyProduct } from "services/companyProducts/companyProductsTypes";
import { type FormationExpressStore } from "services/formationExpress/formationExpressStore";
import { type BackendFormValidationError, type UpdateFormCallback } from "types/form";

type ConfirmStepState = "default" | "loading" | "expired";

export class ConfirmStepStore {
  private _state: ConfirmStepState = "default";

  constructor(
    private readonly _callback: UpdateFormCallback,
    private readonly _companyProductsStore: CompanyProductsStore,
    private readonly _formationExpressStore: FormationExpressStore,
  ) {
    makeAutoObservable(this);
  }

  get state(): ConfirmStepState {
    return this._state;
  }

  set state(value: ConfirmStepState) {
    this._state = value;
  }

  async onSubmit(): Promise<BackendFormValidationError> {
    this.state = "loading";

    if (this._companyProductsStore.hasProduct(CompanyProduct.SameDayRegistration)) {
      await this._formationExpressStore.refresh();

      if (!this._formationExpressStore.featureAvailable) {
        this.state = "expired";
        return;
      }
    }

    return await this._callback();
  }

  async deleteFormationExpress(): Promise<void> {
    await this._companyProductsStore.deleteProduct(CompanyProduct.SameDayRegistration);
  }
}
