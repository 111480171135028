import { ApiClient } from "@anna-money/anna-web-lib";
import { type NavigateFunction } from "react-router-dom";
import { Memoize } from "typescript-memoize";
import { AuthenticationHandler } from "./auth/authenticationApiHandler";
import { BaseServices, Services } from "./services";
import { AcnRegisterFormStepsStore } from "./services/acnRegisterForm/acnRegisterFormStepsStore";
import { AcnRegisterFormStore } from "./services/acnRegisterForm/acnRegisterFormStore";
import { CompanyAddressStore } from "./services/companyAddress/au/companyAddressStore";
import { CompanyAddressFormStore } from "./services/companyAddressForm/au/companyAddressFormStore";
import { FeatureStore } from "./services/feature/featureStore";
import { PricingPlanStore } from "./services/pricingPlan/au/pricingPlanStore";
import { PricingPlanFormStore } from "./services/pricingPlanForm/au/pricingPlanFormStore";
import { ProfileStore } from "./services/profile/profileStore";
import { CompanyQuestionsStore } from "./services/questions/au/companyQuestionsStore";
import { DirectorQuestionsStore } from "./services/questions/au/directorQuestionsStore";
import { SignUpPageStore } from "./services/sighUpPage/au/signUpPageStore";
import { CompanyStepStore } from "./services/stepStore/au/companyStepStore";
import type { UserClient } from "./services/user/userClient";
import { UserStore } from "./services/user/userStore";

export class ServicesAu extends Services {
  constructor(
    protected readonly _baseServices: BaseServices,
    readonly _authenticationHandler: AuthenticationHandler,
    readonly _apiClient: ApiClient,
    readonly _userClient: UserClient,
    readonly _userStore: UserStore,
    readonly _profileStore: ProfileStore,
    readonly _featureStore: FeatureStore,
    readonly _navigate: NavigateFunction,
  ) {
    super(
      _baseServices,
      _authenticationHandler,
      _apiClient,
      _userClient,
      _userStore,
      _profileStore,
      _featureStore,
      _navigate,
    );
  }

  @Memoize()
  get companyAddressStore(): CompanyAddressStore {
    return new CompanyAddressStore(this.companyStore, this.addressClient);
  }

  @Memoize()
  get companyStepStore(): CompanyStepStore {
    return new CompanyStepStore(
      this.companyStore,
      this.companyAddressStore,
      this.companyProductsStore,
      this.pricingPlanStore,
    );
  }

  @Memoize()
  get pricingPlanStore(): PricingPlanStore {
    return new PricingPlanStore(this.companyStore);
  }

  @Memoize()
  get companyQuestionsStore(): CompanyQuestionsStore {
    return new CompanyQuestionsStore(this.companyStore, this.companyAddressStore, this.issuesStore);
  }

  @Memoize()
  get directorQuestionsStore(): DirectorQuestionsStore {
    return new DirectorQuestionsStore(this.directorStore, this.directorAddressStore, this.issuesStore);
  }

  @Memoize()
  get registerFormStepsStore(): AcnRegisterFormStepsStore {
    return new AcnRegisterFormStepsStore(
      this.companyQuestionsStore,
      this.directorQuestionsStore,
      this.issuesStore,
      this.analyticsManager,
    );
  }

  createCompanyAddressFormStore = (): CompanyAddressFormStore => new CompanyAddressFormStore(this.companyAddressStore);

  createPricingPlanFormStore = (): PricingPlanFormStore => {
    return new PricingPlanFormStore(this.pricingPlanStore);
  };

  createAcnRegisterFormStore = (): AcnRegisterFormStore => {
    return new AcnRegisterFormStore(
      this.directorStore,
      this.companyStore,
      this.companyAddressStore,
      this.companyProductsStore,
      this.paymentMethodsStore,
      this.userStore,
      this.analyticsManager,
      this.registerFormStepsStore,
      this.incorporationStore,
      this.issuesStore,
      this.companyStepStore,
    );
  };

  createSignUpPageStore = (): SignUpPageStore =>
    new SignUpPageStore(
      this.companyClient,
      this.leadClient,
      this.analyticsManager,
      this.userStore,
      this._navigate,
      this.config,
    );
}
