import { FormUpdateMethod } from "helpers/decorators/formUpdateMethod";
import type { ParticularsFormValues } from "helpers/forms/particularsTypes";
import { CompanyStore } from "services/company/companyStore";
import { DirectorStore } from "services/director/directorStore";
import { FormError } from "types/errors";
import { type BackendFormValidationError } from "types/form";

export class ParticularsStepStore {
  private readonly _directorStore: DirectorStore;
  private readonly _companyStore: CompanyStore;

  constructor(directorStore: DirectorStore, companyStore: CompanyStore) {
    this._directorStore = directorStore;
    this._companyStore = companyStore;
  }

  @FormUpdateMethod()
  async updateShares(formValues: ParticularsFormValues): Promise<BackendFormValidationError> {
    const { totalShares, totalCapital, directors: directorShares } = formValues || {};

    if (!totalShares || !totalCapital || !directorShares) {
      throw new FormError("Something went wrong");
    }

    await this._companyStore.updateShares(totalShares, totalCapital);

    for (const director of this._directorStore.directors) {
      await this._directorStore.updateShares(
        director.id,
        directorShares[director.id] ? parseInt(directorShares[director.id]) : undefined,
      );
    }
  }
}
