import { AnnaError } from "@anna-money/anna-web-lib";
import { makeAutoObservable, reaction } from "mobx";
import { isSomeEnum } from "helpers/enum";
import { type AnalyticsManager } from "services/analytics/analyticsManager";
import { type CompanyStore } from "services/company/companyStore";
import { type CompanyAddressStore } from "services/companyAddress/gb/companyAddressStore";
import { type CompanyProductsStore } from "services/companyProducts/companyProductsStore";
import { CompanyProduct, VirtualOfficeBillingPeriod } from "services/companyProducts/companyProductsTypes";
import { type FormationExpressStore } from "services/formationExpress/formationExpressStore";
import { type PricingPlanStore } from "services/pricingPlan/gb/pricingPlanStore";
import { FormError } from "types/errors";

export enum BillingItem {
  VirtualOffice = "VirtualOffice",
  Taxes = "Taxes",
  VatRegistration = "VatRegistration",
  ConfirmationStatement = "ConfirmationStatement",
  CancellationCover = "CancellationCover",
  PayeRegistration = "PayeRegistration",
  FormationExpress = "FormationExpress",
}

class CompanyProductToBillingItemMapper {
  constructor(private readonly _map: { [key in CompanyProduct]?: BillingItem }) {}

  get billingItems(): BillingItem[] {
    return Object.values(this._map);
  }

  getProduct(billingItem: BillingItem): CompanyProduct {
    for (const product of Object.keys(this._map) as CompanyProduct[]) {
      if (this._map[product] === billingItem) {
        return product;
      }
    }
    throw new AnnaError(`No product assosiated with the item ${billingItem}`);
  }
}

const mapper = new CompanyProductToBillingItemMapper({
  [CompanyProduct.VirtualOffice]: BillingItem.VirtualOffice,
  [CompanyProduct.Taxes]: BillingItem.Taxes,
  [CompanyProduct.VatRegistration]: BillingItem.VatRegistration,
  [CompanyProduct.ConfirmationStatement]: BillingItem.ConfirmationStatement,
  [CompanyProduct.CancellationCover]: BillingItem.CancellationCover,
  [CompanyProduct.PayeRegistration]: BillingItem.PayeRegistration,
  [CompanyProduct.SameDayRegistration]: BillingItem.FormationExpress,
});

type CheckoutBasketStoreState = "default" | "virtual-office-delete";

export class CheckoutBasketStore {
  private _addedItems: BillingItem[] = [];
  private _additionalItems: BillingItem[] = [];
  private _state: CheckoutBasketStoreState = "default";

  processingItem: Nullable<BillingItem> = null;

  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _companyAddressStore: CompanyAddressStore,
    private readonly _companyProductsStore: CompanyProductsStore,
    private readonly _formationExpressStore: FormationExpressStore,
    private readonly _pricingPlanStore: PricingPlanStore,
    private readonly _analyticsManager: AnalyticsManager,
  ) {
    this._distributeItems();

    makeAutoObservable(this);

    void this._formationExpressStore.refresh();

    reaction(
      () => [this._companyStore.company?.pricingPlan, this._companyProductsStore.productsData],
      this._distributeItems.bind(this),
    );
  }

  get state(): CheckoutBasketStoreState {
    return this._state;
  }

  private set state(value: CheckoutBasketStoreState) {
    this._state = value;
  }

  get addedItems(): BillingItem[] {
    return this._addedItems;
  }

  set addedItems(value: BillingItem[]) {
    this._addedItems = value;
  }

  get additionalItems(): BillingItem[] {
    return this._additionalItems;
  }

  set additionalItems(value: BillingItem[]) {
    this._additionalItems = value;
  }

  get showFormationExpress(): boolean {
    return this._formationExpressStore.needToShow;
  }

  cancelVirtualOfficeDeleting(): void {
    this.state = "default";
    this._distributeItems();
  }

  async addItem(item: BillingItem, data?: unknown): Promise<void> {
    this._analyticsManager.event("basket.add-product", { product: item });

    this.processingItem = item;

    switch (item) {
      case BillingItem.VirtualOffice: {
        const isVoBillingPeriod = isSomeEnum(VirtualOfficeBillingPeriod);
        if (!isVoBillingPeriod(data)) {
          throw new FormError("Wrong billing period");
        }
        await this._companyAddressStore.updateAddress({ virtualOfficeBillingPeriod: data });
        break;
      }
      default: {
        const product = mapper.getProduct(item);
        await this._companyProductsStore.addProduct(product);
      }
    }

    this._distributeItems();

    this.processingItem = null;
  }

  async removeItem(item: BillingItem): Promise<void> {
    this._analyticsManager.event("basket.remove-product", { product: item });

    if (item === BillingItem.VirtualOffice) {
      this.state = "virtual-office-delete";
      return;
    }

    this.processingItem = item;

    const product = mapper.getProduct(item);
    await this._companyProductsStore.deleteProduct(product);

    this._distributeItems();

    this.processingItem = null;
  }

  private _distributeItems(): void {
    type ItemDistributionResult = { addedItems: BillingItem[]; additionalItems: BillingItem[] };
    const itemDistribution = mapper.billingItems
      .filter((billingItem) => {
        if (billingItem === BillingItem.FormationExpress) {
          // Formation Express card has it‘s own place
          return false;
        }
        return !this._pricingPlanStore.isProductIncluded(mapper.getProduct(billingItem));
      })
      .reduce<ItemDistributionResult>(
        (result, billingItem) => {
          switch (billingItem) {
            case BillingItem.VirtualOffice:
              if (this._companyProductsStore.hasProduct(CompanyProduct.VirtualOffice)) {
                result.addedItems.push(billingItem);
              } else {
                result.additionalItems.push(billingItem);
              }
              break;
            default: {
              if (this._companyProductsStore.hasProduct(mapper.getProduct(billingItem))) {
                result.addedItems.push(billingItem);
              } else {
                result.additionalItems.push(billingItem);
              }
            }
          }

          return result;
        },
        { addedItems: [], additionalItems: [] },
      );

    this.addedItems = itemDistribution.addedItems;
    this.additionalItems = itemDistribution.additionalItems;
  }
}
