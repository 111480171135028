import { returnQuestionParam } from "auth/authPages";
import { isSomeEnum } from "helpers/enum";
import { type IssuesStore } from "services/issues/issuesStore";
import { BasketQuestion } from "services/questions/gb/basketConstants";
import { QuestionsStore } from "services/questions/questionsStore";

const INITIAL_QUESTIONS = Object.values(BasketQuestion);

export class BasketQuestionsStore extends QuestionsStore<BasketQuestion> {
  protected _questionsArray = INITIAL_QUESTIONS;
  protected _activeQuestionKey = this._questionsArray[0];
  protected _issueKey = null;
  protected _issueFieldsToQuestions = null;

  constructor(protected readonly _issuesStore: IssuesStore) {
    super(_issuesStore);
  }

  goToRelevantQuestion(): void {
    const searchParams = new URLSearchParams(window.location.search);
    const returnQuestion = searchParams.get(returnQuestionParam);
    const isBasketQuestion = isSomeEnum(BasketQuestion);

    if (isBasketQuestion(returnQuestion)) {
      this.goToQuestion(returnQuestion);
      return;
    }

    this.goToQuestion(this.firstQuestion);
  }
}
