export const parseDate = (date: string): Date => {
  const splittedDate = date.split("/").map((item) => (item[0] === "0" ? parseInt(item[1]) : parseInt(item)));
  return new Date(splittedDate[2], splittedDate[1] - 1, splittedDate[0]);
};

const normalizeDate = (date: Date): { day: string; month: string; year: string } => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  let dayStr = day.toString();
  let monthStr = month.toString();

  if (day < 10) {
    dayStr = "0" + day.toString();
  }

  if (month < 10) {
    monthStr = `0${month}`;
  }

  return {
    day: dayStr,
    month: monthStr,
    year: date.getFullYear().toString(),
  };
};

export const formatDateYYYYMMDD = (date: Date): string => {
  const normalizedDate = normalizeDate(date);
  return `${normalizedDate.year}-${normalizedDate.month}-${normalizedDate.day}`;
};

export const formatDateDDMMYYYY = (date: Date): string => {
  const normalizedDate = normalizeDate(date);

  return `${normalizedDate.day}/${normalizedDate.month}/${normalizedDate.year}`;
};
