// eslint-disable-next-line import/no-unresolved
import { type AnyEventName, type EventPayload, type WebSDKEvents } from "@sumsub/websdk/types";
import { makeAutoObservable } from "mobx";
import { type AnalyticsManager } from "services/analytics/analyticsManager";
import { type SumsubApplicationStore } from "services/sumsub/sumsubApplicationStore";
import { type SumsubClient } from "services/sumsub/sumsubClient";
import { SumsubCreateApplicationData } from "services/sumsub/sumsubTypes";
import { type SumsubToken } from "./types";

type DirectorIdentityWidgetState = "pending" | "success";

export class DirectorIdentityWidgetStore {
  private _token: SumsubToken;
  private _state: DirectorIdentityWidgetState = "pending";
  private _applicationCheckInterval?: number;

  constructor(
    private readonly _sumsubClient: SumsubClient,
    private readonly _analyticsManager: AnalyticsManager,
    private readonly _sumsubStore: SumsubApplicationStore,
  ) {
    makeAutoObservable(this);
  }

  get token(): SumsubToken {
    return this._token;
  }

  private set token(value: SumsubToken) {
    this._token = value;
  }

  get state(): DirectorIdentityWidgetState {
    return this._state;
  }

  set state(value: DirectorIdentityWidgetState) {
    this._state = value;
  }

  async getSumsubToken(): Promise<string> {
    const sumsubToken = await this._sumsubClient.getSumsubToken();
    this.token = sumsubToken;
    return sumsubToken;
  }

  async invokePendingApplicationCheck(): Promise<void> {
    if (this._applicationCheckInterval) {
      return;
    }

    this._applicationCheckInterval = window.setInterval(async () => {
      await this._sumsubStore.getSumsubApplication();

      if (this._sumsubStore.isPassedVerifications) {
        this.stopPengingApplicationCheck();
        this._analyticsManager.event("sumsub-application-received");
      }
    }, 3000);
  }

  async onMessage(message: AnyEventName, payload: EventPayload<AnyEventName>): Promise<void> {
    this._analyticsManager.event(`sumsub-message.${message}`, payload);

    switch (message) {
      case "idCheck.onApplicantLoaded":
        await this._onApplicantLoaded(payload as WebSDKEvents["idCheck.onApplicantLoaded"]);
        break;
      // @ts-expect-error no types for this event
      case "idCheck.applicantReviewComplete":
        await this._onApplicantReviewComplete();
        break;
    }
  }

  stopPengingApplicationCheck(): void {
    window.clearInterval(this._applicationCheckInterval);
    this._applicationCheckInterval = undefined;
  }

  private async _onApplicantLoaded(payload: WebSDKEvents["idCheck.onApplicantLoaded"]): Promise<void> {
    await this._sumsubClient.createSumsubApplication(new SumsubCreateApplicationData(payload.applicantId));
    this._analyticsManager.event("start-sumsub");
  }

  private async _onApplicantReviewComplete(): Promise<void> {
    this.state = "success";
    this._analyticsManager.event("finish-sumsub");
  }
}
