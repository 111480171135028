import { makeAutoObservable } from "mobx";

import { type CompanyStore } from "services/company/companyStore";
import { PricingPlan, type PricingPlanAu } from "services/pricingPlan/pricingPlanTypes";

type PricingPlanData = { name: PricingPlanAu; price: number; vat: number };

export const PRICING_PLAN_VIEW_DATA = {
  [PricingPlan.AnnaOne]: [
    {
      title: "ANNA One",
      oldPrice: null,
      price: 619,
      remark: "One year introductory fee",
    },
    {
      title: "Company registration",
      oldPrice: 630,
      price: 199,
      remark: "ASIC $576 fee is on us!",
    },
  ],
  [PricingPlan.EasyCompany]: [
    {
      title: "Easy Company",
      oldPrice: null,
      price: 630,
      remark: "ASIC $576 fee included",
    },
  ],
};

export class PricingPlanStore {
  private readonly _pricingPlanData: PricingPlanData[] = [
    {
      name: PricingPlan.EasyCompany,
      price: 630,
      vat: 63,
    },
    {
      name: PricingPlan.AnnaOne,
      price: 818,
      vat: 81.8,
    },
  ];

  constructor(private readonly _companyStore: CompanyStore) {
    makeAutoObservable(this);
  }

  getCurrentPlanData(): PricingPlanData | undefined {
    const pricingPlan = this._companyStore.getCompany().pricingPlan;

    if (pricingPlan) {
      return this._pricingPlanData.find((plan) => plan.name === pricingPlan);
    }

    return undefined;
  }

  async updatePricingPlan(pricingPlan: PricingPlanAu): Promise<void> {
    await this._companyStore.updatePricingPlan(pricingPlan);
  }
}
