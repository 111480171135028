import { type ApiClient } from "@anna-money/anna-web-lib";

import { type PricingPlanProducts, PricingPlansProductsData } from "./pricingPlanTypes";

export class PricingPlanClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async getPricingPlanProducts(): Promise<PricingPlanProducts> {
    const response = await this._apiClient.get("api/formations/pricing-plan/products");

    return response.getData(PricingPlansProductsData).products;
  }
}
