import { AnnaError } from "@anna-money/anna-web-lib";
import { makeAutoObservable } from "mobx";

import { isGbPricingPlan } from "helpers/pricingPlan";
import { type CompanyStore } from "services/company/companyStore";
import { type CompanyProductsStore } from "services/companyProducts/companyProductsStore";
import { CompanyProduct, VirtualOfficeBillingPeriod } from "services/companyProducts/companyProductsTypes";
import { PricingPlan, type PricingPlanGb, type PricingPlanViewInfo } from "services/pricingPlan/pricingPlanTypes";
import type { UserStore } from "services/user/userStore";
import { type PricingPlanClient } from "./pricingPlanClient";
import { type PricingPlanProducts } from "./pricingPlanTypes";

export class PricingPlanStore {
  private _pricingPlanProducts?: PricingPlanProducts;

  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _companyProductsStore: CompanyProductsStore,
    private readonly _userStore: UserStore,
    private readonly _pricingPlanClient: PricingPlanClient,
  ) {
    makeAutoObservable(this);
  }

  get pricingPlanProducts(): PricingPlanProducts | undefined {
    return this._pricingPlanProducts;
  }

  private set pricingPlanProducts(value) {
    this._pricingPlanProducts = value;
  }

  async init(): Promise<void> {
    this.pricingPlanProducts = await this._pricingPlanClient.getPricingPlanProducts();
  }

  tryGetPricingPlan(): PricingPlanGb | undefined {
    const pricingPlan = this._companyStore.getCompany().pricingPlan;

    if (!pricingPlan) {
      return undefined;
    }

    if (!isGbPricingPlan(pricingPlan)) {
      throw new AnnaError(`Pricing plan ${pricingPlan} is not expected`);
    }

    return pricingPlan;
  }

  getPricingPlan(): PricingPlanGb {
    const pricingPlan = this.tryGetPricingPlan();

    if (!pricingPlan) {
      throw new AnnaError("No pricing plan selected");
    }

    return pricingPlan;
  }

  getPricingPlanViewInfo(pricingPlan: PricingPlanGb): PricingPlanViewInfo {
    const enhancedBundleExperiment = this._userStore.cluster;

    switch (pricingPlan) {
      case PricingPlan.JustRegister:
        return {
          title: "Basic",
          price: enhancedBundleExperiment ? "£19" : "£50",
          remark: "Companies House fee only",
        };
      case PricingPlan.FullFormation19:
        return {
          title: "Enhanced",
          price: enhancedBundleExperiment ? "£79 +VAT" : "£19 +VAT",
          oldPrice: enhancedBundleExperiment ? "£178" : "£90",
          remark: "Companies House £50 fee on us",
        };
      case PricingPlan.FullFormation:
        return {
          title: "Enhanced",
          price: "£49 +VAT",
          oldPrice: "£90",
          remark: "Companies House £50 fee on us",
        };
      case PricingPlan.AllInOne:
        return {
          title: "Total Support",
          price: "£395 +VAT",
          oldPrice: "£750",
          remark: "Companies House £50 fee on us",
        };
    }
  }

  isProductIncluded(product: CompanyProduct, pricingPlan?: PricingPlan): boolean {
    const plan = pricingPlan || this._companyStore.company?.pricingPlan;

    if (!plan) {
      return false;
    }

    return this._getProductsForPlan(plan).includes(product);
  }

  async updatePricingPlan(pricingPlan: PricingPlanGb): Promise<void> {
    // if user choose pricing plan which is already includes some products –
    // need to remove them if user chose them previously
    await this._deleteAllProducts(pricingPlan);

    const oldPricingPlan = this.tryGetPricingPlan();
    await this._companyStore.updatePricingPlan(pricingPlan);

    await this._restoreVirtualOfficeIfNeeded(pricingPlan, oldPricingPlan);
  }

  private async _deleteAllProducts(pricingPlan: PricingPlanGb): Promise<void> {
    const planProducts = this._getProductsForPlan(pricingPlan);
    for (const product of planProducts) {
      if (this._companyProductsStore.hasProduct(product)) {
        await this._companyProductsStore.deleteProduct(product);
      }
    }
  }

  private async _restoreVirtualOfficeIfNeeded(
    pricingPlan: PricingPlanGb,
    oldPricingPlan?: PricingPlanGb,
  ): Promise<void> {
    if (!oldPricingPlan || this.isProductIncluded(CompanyProduct.VirtualOffice, pricingPlan)) {
      return;
    }

    const needToRestoreVO = oldPricingPlan && this.isProductIncluded(CompanyProduct.VirtualOffice, oldPricingPlan);
    if (needToRestoreVO) {
      await this._companyProductsStore.addProduct(CompanyProduct.VirtualOffice, VirtualOfficeBillingPeriod.Yearly);
    }
  }

  private _getProductsForPlan(pricingPlan: PricingPlan): CompanyProduct[] {
    return this.pricingPlanProducts ? this.pricingPlanProducts[pricingPlan] : [];
  }
}
