import { type CustomSerializer, Serializer, convertCase } from "@anna-money/anna-web-lib";
import { Datetime } from "types/date";

export const DatetimeSerializer: CustomSerializer<string, Datetime> = {
  type: Datetime,
  serialize: (x) => x.toISOString(),
  deserialize: (x) => {
    if (typeof x !== "string") {
      throw new Error(`Expected date field to be a string but ${x as string} received`);
    }
    return Datetime.parse(x);
  },
};

export const apiSerializer = new Serializer({
  formatPropertyNames: convertCase.bind(null, "camel", "snake"),
  propertiesRequired: true,
  serializers: [DatetimeSerializer],
});
