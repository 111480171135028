import { makeAutoObservable } from "mobx";
import { FormUpdateMethod } from "helpers/decorators/formUpdateMethod";
import { consentFormToConsentData } from "helpers/forms/consent";
import type { ConsentFormValues } from "helpers/forms/consentTypes";
import type { BackendFormValidationError } from "types/form";
import { ConsentClient } from "./consentClient";
import { ConsentType } from "./consentTypes";

export class ConsentStore {
  private _consents: ConsentType[] = [];

  constructor(private readonly _consentClient: ConsentClient) {
    makeAutoObservable(this);
  }

  get consentsData(): ConsentType[] {
    return this._consents;
  }

  private set consentsData(consents: ConsentType[]) {
    this._consents = consents;
  }

  async init(): Promise<void> {
    this.consentsData = await this._consentClient.getConsents();
  }

  @FormUpdateMethod()
  async updateConsents(values: ConsentFormValues): Promise<BackendFormValidationError> {
    this.consentsData = await this._consentClient.acceptConsents(consentFormToConsentData(values));
  }
}
