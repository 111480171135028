import { type DirectorAddressStore } from "services/director/directorAddressStore";
import { type DirectorStore } from "services/director/directorStore";
import { IssueKey, type IssuesStore } from "services/issues/issuesStore";
import { QuestionsStore } from "services/questions/questionsStore";
import { DIRECTOR_FIELD_TO_QUESTION, DirectorQuestion } from "./directorConstants";

export class DirectorQuestionsStore extends QuestionsStore<DirectorQuestion> {
  protected _questionsArray = Object.values(DirectorQuestion);
  protected _activeQuestionKey = this._questionsArray[0];
  protected _issueKey = IssueKey.Director;
  protected _issueFieldsToQuestions = DIRECTOR_FIELD_TO_QUESTION;

  constructor(
    private readonly _directorStore: DirectorStore,
    private readonly _directorAddressStore: DirectorAddressStore,
    protected readonly _issuesStore: IssuesStore,
  ) {
    super(_issuesStore);
  }

  goToRelevantQuestion(): void {
    const { mainDirector } = this._directorStore;

    if (!mainDirector) {
      this.goToQuestion(DirectorQuestion.Intro);
      return;
    }

    if (!mainDirector.firstName || !mainDirector.lastName) {
      this.goToQuestion(DirectorQuestion.Name);
      return;
    }

    if (!mainDirector.dob) {
      this.goToQuestion(DirectorQuestion.Birthday);
      return;
    }

    const address = this._directorAddressStore.tryGetForDirector(mainDirector.id);
    if (!address) {
      this.goToQuestion(DirectorQuestion.Address);
      return;
    }

    if (!mainDirector.tnf) {
      this.goToQuestion(DirectorQuestion.TaxFileNumber);
      return;
    }

    this.goToQuestion(this.firstQuestionWithIssue || this.lastQuestion);
  }
}
