import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";
import { CompanyProduct } from "services/companyProducts/companyProductsTypes";
import { PricingPlan } from "services/pricingPlan/pricingPlanTypes";

export type PricingPlanProducts = Record<PricingPlan, CompanyProduct[]>;

@Serializable()
export class PricingPlansProductsData {
  constructor(@JsonProperty({ type: Object }) readonly products: PricingPlanProducts) {}
}
