import { AnnaErrorBase, HttpClient, type HttpResponse, type Nullable, getHttpError } from "@anna-money/anna-web-lib";

export abstract class AnalyticsErrorBase extends AnnaErrorBase {}

export class AnalyticsClientError extends AnalyticsErrorBase {
  name = "AnalyticsClientError";

  constructor(message: string, cause: unknown) {
    super(message, { cause });
  }
}

export class AnalyticsServerError extends AnalyticsErrorBase {
  name = "AnalyticsServerError";

  constructor(message: string, cause: unknown) {
    super(message, { cause });
  }
}

export class AnalyticsClient {
  private readonly _client: Nullable<HttpClient>;

  constructor(apiUrl: Nullable<string>) {
    if (!apiUrl) {
      // To ensure that we know about it in production
      console.warn("Analytics client is not configured");
    } else {
      this._client = new HttpClient(apiUrl);
    }
  }

  async postEvent(eventType: string, alias: string, payload?: unknown): Promise<void> {
    if (!this._client || process.env.NODE_ENV === "development") {
      console.log("analytics push event", eventType, alias, { payload });
      return;
    }

    const response = await AnalyticsClient._postEvent(this._client, eventType, alias, payload);

    if (!response.isOk) {
      throw new AnalyticsServerError("Failed to send analytics", getHttpError(response.diagnostics));
    }
  }

  private static async _postEvent(
    client: HttpClient,
    eventType: string,
    alias: string,
    payload?: unknown,
  ): Promise<HttpResponse<any>> {
    try {
      const request = await client.request({
        method: "post",
        url: "/api/webtics/i/event",
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
        data: JSON.stringify({
          event_type: `formation.${eventType}`,
          payload,
          alias,
        }),
      });
      return await request.send();
    } catch (e) {
      throw new AnalyticsClientError("Failed to send analytics", e);
    }
  }
}
