import { type FC, Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { Loader } from "components/Loader/Loader";

const Element = lazy(async () => await import("../../components/IncorporationRegisterForm/IncorporationRegisterForm"));

export const IncorporationRegisterFormRoutes: FC = () => (
  <Routes>
    <Route
      index
      path="*"
      element={
        <Suspense fallback={<Loader size="l" />}>
          <Element />
        </Suspense>
      }
    />
  </Routes>
);
