import type { NavigateFunction } from "react-router-dom";
import type { Config } from "config/config";
import type { AnalyticsManager } from "services/analytics/analyticsManager";
import type { CompanyClient } from "services/company/companyClient";
import { type CompanyData, CompanyNamePostfix } from "services/company/companyTypes";
import type { LeadClient } from "services/lead/leadClient";
import { SignUpPageStoreBase } from "services/sighUpPage/signUpPageStoreBase";
import { CookieName, type CookieStorage } from "services/storage/cookieStorage";
import type { UserStore } from "services/user/userStore";

export class SignUpPageStore extends SignUpPageStoreBase {
  protected _defaultPostfix = CompanyNamePostfix.Ltd;

  constructor(
    protected readonly _companyClient: CompanyClient,
    protected readonly _leadClient: LeadClient,
    protected readonly _analyticsManager: AnalyticsManager,
    protected readonly _userStore: UserStore,
    protected readonly _navigate: NavigateFunction,
    protected readonly _config: Config,
    private readonly _cookieStorage: CookieStorage,
  ) {
    super(_companyClient, _leadClient, _analyticsManager, _userStore, _navigate, _config);
  }

  protected async _proceedSignUp(): Promise<undefined | CompanyData> {
    const company = await super._proceedSignUp();

    const enhancedBundleExperiment = this._cookieStorage.get(CookieName.EnhancedBundleExperiment, Number);
    if (enhancedBundleExperiment === 1 && !this._userStore.cluster) {
      await this._userStore.createCluster(enhancedBundleExperiment);
    }

    return company;
  }
}
