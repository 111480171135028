import { addressFormToCreateData, addressFormToUpdateData } from "helpers/forms/address";
import { type AddressForm } from "helpers/forms/addressTypes";
import { type AddressClient, AddressKey } from "services/address/addressClient";
import { type AddressData } from "services/address/addressTypes";
import { type CompanyStore } from "services/company/companyStore";
import { type CompanyData } from "services/company/companyTypes";
import { CompanyAddressStoreBase } from "services/companyAddress/companyAddressStoreBase";
import { FormError } from "types/errors";

export class CompanyAddressStore extends CompanyAddressStoreBase {
  constructor(
    protected readonly _companyStore: CompanyStore,
    protected readonly _addressClient: AddressClient,
  ) {
    super(_companyStore, _addressClient);
  }

  async init(): Promise<void> {
    const company = this._companyStore.company;
    if (company) {
      this.address = await this._addressClient.getAddress(AddressKey.Company, company.id);
    }
  }

  async updateAddress(address?: AddressForm): Promise<void> {
    await this._updateAddress(address);
  }

  private async _updateAddress(address?: AddressForm): Promise<void> {
    if (!address) {
      throw new FormError("Please select address");
    }

    const company = this._companyStore.getCompany();

    this.address = await this._createOrUpdateAddress(company, address);
  }

  private async _createOrUpdateAddress(company: CompanyData, newAddress: AddressForm): Promise<AddressData> {
    const address = this.address;
    const handler = address
      ? async () => await this._addressClient.updateAddress(address.id, addressFormToUpdateData(newAddress))
      : async () =>
          await this._addressClient.createAddress(addressFormToCreateData(newAddress, { companyId: company.id }));
    return await handler();
  }
}
