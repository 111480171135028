import { type AnalyticsManager } from "services/analytics/analyticsManager";
import { type IssuesStore } from "services/issues/issuesStore";
import { type CompanyQuestionsStore } from "services/questions/au/companyQuestionsStore";
import { type DirectorQuestionsStore } from "services/questions/au/directorQuestionsStore";
import {
  RegisterFormStep,
  RegisterFormSteps,
  RegisterFormStepsStoreBase,
} from "services/registerForm/registerFormStepsStoreBase";
import { AcnFormStep } from "./constants";

export class AcnRegisterFormStepsStore extends RegisterFormStepsStoreBase<AcnFormStep> {
  protected readonly _steps: RegisterFormSteps<AcnFormStep> = new RegisterFormSteps<AcnFormStep>([
    new RegisterFormStep(AcnFormStep.BEFORE_STARTED, "How it works"),
    new RegisterFormStep(AcnFormStep.WHAT_TO_EXPECT, "How it works"),
    new RegisterFormStep(AcnFormStep.COMPANY, "About your business", this._companyQuestionsStore),
    new RegisterFormStep(AcnFormStep.DIRECTOR, "About you (The director)", this._directorQuestionsStore),
    new RegisterFormStep(AcnFormStep.BASKET, "Your basket"),
    new RegisterFormStep(AcnFormStep.CONFIRM, "Confirm and submit"),
    new RegisterFormStep(AcnFormStep.COMPLETE, "Complete"),
  ]);

  constructor(
    private readonly _companyQuestionsStore: CompanyQuestionsStore,
    private readonly _directorQuestionsStore: DirectorQuestionsStore,
    protected readonly _issuesStore: IssuesStore,
    protected readonly _analyticsManager: AnalyticsManager,
  ) {
    super(_issuesStore, _analyticsManager);
  }
}
