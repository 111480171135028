import { FormUpdateMethod } from "helpers/decorators/formUpdateMethod";
import { type CompanyFormValues } from "helpers/forms/gb/companyTypes";
import { CompanyAddressStore } from "services/companyAddress/gb/companyAddressStore";
import { FormError } from "types/errors";
import type { BackendFormValidationError } from "types/form";

export type CompanyAddressFormValues = Pick<
  CompanyFormValues,
  "useVirtualOffice" | "virtualOfficeBillingPeriod" | "address"
>;

export class CompanyAddressFormStore {
  constructor(private readonly _companyAddressStore: CompanyAddressStore) {}

  @FormUpdateMethod()
  async onSubmit(values: CompanyAddressFormValues): Promise<BackendFormValidationError> {
    if (values.useVirtualOffice && values.virtualOfficeBillingPeriod) {
      await this._companyAddressStore.updateAddress({
        virtualOfficeBillingPeriod: values.virtualOfficeBillingPeriod,
      });
      return;
    }

    if (values.address) {
      await this._companyAddressStore.updateAddress({ address: values.address });
      return;
    }

    throw new FormError("No virtual office and no address selected");
  }
}
