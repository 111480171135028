import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";

@Serializable()
export class DirectorData {
  constructor(
    @JsonProperty() readonly id: string,
    @JsonProperty() readonly companyId: string,
    @JsonProperty() readonly created: string,
    @JsonProperty() readonly updated: string,
    @JsonProperty({ required: false, type: String }) readonly firstName: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly middleNames: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly lastName: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly dob: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly nationality: Nullable<string>,
    @JsonProperty({ required: false, type: Boolean }) readonly useRegisteredAddressAsService: Nullable<boolean>,
    @JsonProperty({ required: false, type: Number }) readonly ownsSharesNum: Nullable<number>,
    // AU
    @JsonProperty({ required: false, type: String }) readonly countryOfBirth: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly birthSuburb: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly townOfBirth: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly tnf: Nullable<string>,
  ) {}
}

@Serializable()
export class DirectorListData {
  constructor(@JsonProperty({ type: DirectorData, isArray: true }) readonly directors: DirectorData[]) {}
}

@Serializable()
export class MainDirectorCreateData {
  constructor(@JsonProperty() readonly companyId: string) {}
}

@Serializable()
export class DirectorCreateData {
  constructor(
    @JsonProperty() readonly companyId: string,
    @JsonProperty({ required: false, type: String }) readonly firstName: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly middleNames: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly lastName: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly dob: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly nationality: Nullable<string>,
    @JsonProperty({ type: Boolean }) readonly isDirector: boolean,
  ) {}
}

@Serializable()
export class DirectorUpdateData {
  constructor(
    @JsonProperty({ required: false, type: String }) readonly firstName: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly middleNames: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly lastName: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly dob: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly nationality: Nullable<string>,
    @JsonProperty({ type: Boolean }) readonly isDirector: boolean,
    // AU
    @JsonProperty({ required: false, type: String }) readonly countryOfBirth: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly birthSuburb: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly townOfBirth: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly tnf: Nullable<string>,
  ) {}
}

@Serializable()
export class DirectorSharesUpdateData {
  constructor(@JsonProperty({ type: Number }) readonly ownsSharesNum: Nullable<number>) {}
}
