import { makeAutoObservable } from "mobx";

import { FormUpdateMethod } from "helpers/decorators/formUpdateMethod";
import { CompanyStore } from "services/company/companyStore";
import { IncorporationCreateData, IncorporationData } from "services/incorporation/incorporationTypes";
import { type BackendFormValidationError } from "types/form";
import { IncorporationClient } from "./incorporationClient";

export class IncorporationStore {
  private readonly _companyStore: CompanyStore;
  private readonly _incorporationClient: IncorporationClient;

  private _incorporation?: IncorporationData;

  constructor(incorporationClient: IncorporationClient, companyStore: CompanyStore) {
    this._incorporationClient = incorporationClient;
    this._companyStore = companyStore;

    makeAutoObservable(this);
  }

  get incorporation(): IncorporationData | undefined {
    return this._incorporation;
  }

  set incorporation(value: IncorporationData | undefined) {
    this._incorporation = value;
  }

  async init(): Promise<void> {
    this.incorporation = await this._incorporationClient.getIncorporation();
  }

  @FormUpdateMethod()
  async createIncorporation(): Promise<BackendFormValidationError> {
    this.incorporation = await this._incorporationClient.createIncorporation(
      new IncorporationCreateData(this._companyStore.getCompany().id),
    );
  }
}
