import { AnnaError } from "@anna-money/anna-web-lib";
import { makeObservable, observable } from "mobx";
import { type AnalyticsManager } from "services/analytics/analyticsManager";
import { type IssuesStore } from "services/issues/issuesStore";
import { type QuestionsStore } from "services/questions/questionsStore";

export class RegisterFormStep<T, V = unknown> {
  constructor(
    private readonly _type: T,
    private readonly _title: string,
    private readonly _controller?: QuestionsStore<V>,
  ) {}

  get type(): T {
    return this._type;
  }

  get title(): string {
    return this._title;
  }

  get controller(): QuestionsStore<V> | undefined {
    return this._controller;
  }
}

export class RegisterFormSteps<T> {
  private _activeStepIndex?: number;

  constructor(private readonly _steps: Array<RegisterFormStep<T>>) {
    makeObservable(this, {
      _activeStepIndex: observable,
    } as any);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/explicit-module-boundary-types
  *[Symbol.iterator]() {
    for (let index = 0; index < this._steps.length; index++) {
      yield this._steps[index];
    }
  }

  get activeStep(): RegisterFormStep<T> | undefined {
    if (typeof this._activeStepIndex === "undefined") {
      return undefined;
    }

    return this._steps[this._activeStepIndex];
  }

  get nextStep(): RegisterFormStep<T> | undefined {
    if (typeof this._activeStepIndex === "undefined") {
      return undefined;
    }

    const nextStepIndex = this._activeStepIndex + 1;

    if (nextStepIndex > this._steps.length - 1) {
      return undefined;
    }

    return this._steps[nextStepIndex];
  }

  get previousStep(): RegisterFormStep<T> | undefined {
    if (typeof this._activeStepIndex === "undefined") {
      return undefined;
    }

    const prevStepIndex = this._activeStepIndex - 1;

    if (prevStepIndex < 0) {
      return undefined;
    }

    return this._steps[prevStepIndex];
  }

  /**
   * We're assuming that the step before the last one (which is always a "complete" step)
   * is the step we need to redirect customer to if all the issues are resolved
   */
  get confirmStep(): RegisterFormStep<T> {
    return this._steps[this._steps.length - 3];
  }

  activateStep(activatingStepType: T | undefined): void {
    if (!activatingStepType) {
      return;
    }
    this._activeStepIndex = this._steps.findIndex((step) => step.type === activatingStepType);
  }
}

export abstract class RegisterFormStepsStoreBase<T> {
  protected abstract readonly _steps: RegisterFormSteps<T>;

  get steps(): RegisterFormSteps<T> {
    return this._steps;
  }

  get activeStep(): RegisterFormStep<T> | undefined {
    return this.steps.activeStep;
  }

  protected constructor(
    protected readonly _issuesStore: IssuesStore,
    protected readonly _analyticsManager: AnalyticsManager,
  ) {
    makeObservable(this, {
      _steps: observable,
    } as any);
  }

  protected _getPreviousStep(): T {
    if (!this._steps.previousStep?.type) {
      throw new AnnaError("No previous step during attempt to go back");
    }

    return this._steps.previousStep.type;
  }

  async goPreviousQuestion(): Promise<void> {
    if (!this.activeStep) {
      return;
    }

    const { controller } = this.activeStep;

    if (!controller || controller.isFirstQuestion) {
      this.setStep(this._getPreviousStep());
      return;
    }

    await controller.goBack();
  }

  async goNextQuestion(): Promise<void> {
    if (!this.activeStep) {
      throw new AnnaError("No active step into RegisterFormStepsStore");
    }

    const { controller } = this.activeStep;

    if (!controller) {
      this._goNextStep();
      return;
    }

    if (!controller.hasIssues()) {
      (await controller.isLastQuestion()) ? this._goNextStep() : await controller.goNext();
      return;
    }

    await controller.resoveIssueIfNeeded();

    this.goToNextIssue();
  }

  private _goNextStep(): void {
    if (!this._steps.nextStep?.type) {
      throw new AnnaError("No next step during attempt to go forward");
    }
    this.setStep(this._steps.nextStep.type);
  }

  setStep(step: T): void {
    this._steps.activateStep(step);

    if (this._steps.activeStep?.controller) {
      this._steps.activeStep.controller.goToRelevantQuestion();
    }

    this._analyticsManager.event("load-step", { name: step });
  }

  goToNextIssue(): void {
    for (const step of this._steps) {
      if (!step.controller) {
        continue;
      }

      if (step.controller.hasIssues()) {
        this.setStep(step.type);
        return;
      }
    }

    this.setStep(this._steps.confirmStep.type);
  }
}
