import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";

@Serializable()
export class CompanyBusinessActivityData {
  constructor(
    @JsonProperty() readonly businessActivityId: string,
    @JsonProperty() readonly code: string,
    @JsonProperty() readonly description: string,
  ) {}
}

@Serializable()
export class CompanyBusinessActivitiesData {
  constructor(
    @JsonProperty({ type: CompanyBusinessActivityData, isArray: true })
    readonly businessActivities: CompanyBusinessActivityData[],
  ) {}
}

@Serializable()
export class CompanyBusinessActivityCreateData {
  constructor(@JsonProperty() readonly businessActivityId: string) {}
}

@Serializable()
export class CompanyBusinessActivityDeleteData {
  constructor(@JsonProperty() readonly businessActivityId: string) {}
}
