import { makeAutoObservable } from "mobx";

import { type ComplianceClient } from "./complianceClient";
import { type ComplianceData } from "./complianceTypes";

export class ComplianceStore {
  private _checkResult?: ComplianceData;

  constructor(private readonly _complianceClient: ComplianceClient) {
    makeAutoObservable(this);
  }

  get checkResult(): ComplianceData | undefined {
    return this._checkResult;
  }

  set checkResult(value: ComplianceData) {
    this._checkResult = value;
  }

  invokePendingComplianceCheck = async (): Promise<void> => {
    await this._getComplianceCheck();

    if (!this.checkResult?.isFinished) {
      const interval = setInterval(async () => {
        await this._getComplianceCheck();

        if (this.checkResult?.isFinished) {
          clearInterval(interval);
        }
      }, 3000);
    }
  };

  _getComplianceCheck = async (): Promise<void> => {
    try {
      this.checkResult = await this._complianceClient.getComplianceResult();
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (e?.data?.error?.code !== 1040) {
        throw e;
      }
    }
  };
}
