import { makeAutoObservable } from "mobx";

import type { CompanyStore } from "services/company/companyStore";
import type { CompanyBusinessActivitiesClient } from "./companyBusinessActivitiesClient";
import {
  CompanyBusinessActivityCreateData,
  type CompanyBusinessActivityData,
  CompanyBusinessActivityDeleteData,
} from "./companyBusinessActivitiesTypes";

export class CompanyBusinessActivitiesStore {
  private _activities: CompanyBusinessActivityData[] = [];

  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _companyBusinessActivitiesClient: CompanyBusinessActivitiesClient,
  ) {
    makeAutoObservable(this);
  }

  get activities(): CompanyBusinessActivityData[] {
    return this._activities;
  }

  get hasActivities(): boolean {
    return this.activities.length !== 0;
  }

  hasActivityWithId(activityId: string): boolean {
    return this._activities.some((activity) => activity.businessActivityId === activityId);
  }

  async init(): Promise<void> {
    await this._getActivities();
  }

  private async _getActivities(): Promise<void> {
    const companyId = this._companyStore.company?.id;

    if (!companyId) {
      return;
    }

    this._activities = await this._companyBusinessActivitiesClient.getActivities(companyId);
  }

  async createActivity(activityId: string): Promise<void> {
    this._activities = await this._companyBusinessActivitiesClient.createActivity(
      this._companyStore.getCompany().id,
      new CompanyBusinessActivityCreateData(activityId),
    );
  }

  async deleteActivity(activityId: string): Promise<void> {
    this._activities = await this._companyBusinessActivitiesClient.deleteActivity(
      this._companyStore.getCompany().id,
      new CompanyBusinessActivityDeleteData(activityId),
    );
  }
}
