import { type CompanyStore } from "services/company/companyStore";
import { type CompanyAddressStore } from "services/companyAddress/au/companyAddressStore";
import { IssueKey, type IssuesStore } from "services/issues/issuesStore";
import { QuestionsStore } from "services/questions/questionsStore";
import { COMPANY_FIELD_TO_QUESTION, CompanyQuestion } from "./companyConstants";

export class CompanyQuestionsStore extends QuestionsStore<CompanyQuestion> {
  protected _questionsArray = Object.values(CompanyQuestion);
  protected _activeQuestionKey = this._questionsArray[0];
  protected _issueKey = IssueKey.Company;
  protected _issueFieldsToQuestions = COMPANY_FIELD_TO_QUESTION;

  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _companyAddressStore: CompanyAddressStore,
    protected readonly _issuesStore: IssuesStore,
  ) {
    super(_issuesStore);
  }

  goToRelevantQuestion(): void {
    const { company } = this._companyStore;

    if (!company?.companyName) {
      this.goToQuestion(CompanyQuestion.Name);
      return;
    }

    // if (!this._companyProductsStore.hasProduct(CompanyProduct.RegisterBusinessName)) {
    //   this.goToQuestion(CompanyQuestion.BusinessName);
    //   return;
    // }

    if (!company.pricingPlan) {
      this.goToQuestion(CompanyQuestion.PricingPlan);
      return;
    }

    if (!company.stateOfRegistration) {
      this.goToQuestion(CompanyQuestion.Jurisdiction);
      return;
    }

    if (!this._companyAddressStore.address) {
      this.goToQuestion(CompanyQuestion.Address);
      return;
    }

    if (typeof this._companyAddressStore.address.isOwnedByCompany === "undefined") {
      this.goToQuestion(CompanyQuestion.Occupier);
      return;
    }

    this.goToQuestion(this.lastQuestion);
  }
}
