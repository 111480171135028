import { type IssueFieldQuestionMap } from "services/issues/issuesStore";

export enum DirectorQuestion {
  Intro = "DIRECTOR_INTRO",
  Name = "DIRECTOR_NAME",
  Birthday = "DIRECTOR_BIRTHDAY",
  Address = "DIRECTOR_ADDRESS",
  TaxFileNumber = "DIRECTOR_TAX_FILE_NUMBER",
}

export const DIRECTOR_FIELD_TO_QUESTION: IssueFieldQuestionMap<DirectorQuestion> = {
  dob: DirectorQuestion.Birthday,
  first_name: DirectorQuestion.Name,
  middle_names: DirectorQuestion.Name,
  last_name: DirectorQuestion.Name,
  address: DirectorQuestion.Address,
  service_address: DirectorQuestion.Address,
};

export const DIRECTOR_FIELD_TO_PATH: Record<DirectorQuestion, string> = {
  [DirectorQuestion.Intro]: "intro",
  [DirectorQuestion.Name]: "name",
  [DirectorQuestion.Birthday]: "birthday",
  [DirectorQuestion.Address]: "address",
  [DirectorQuestion.TaxFileNumber]: "tfn",
};
