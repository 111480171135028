import { type FC } from "react";
import { AppVersion } from "./components/AppVersion/AppVersion";
import Logo from "./components/Logo/Logo";

import styles from "./styles.module.scss";

const Header: FC = () => (
  <div className={styles.header}>
    <Logo />
    <AppVersion />
  </div>
);

export default Header;
