import { makeObservable, observable } from "mobx";
import { type AddressClient, AddressKey } from "services/address/addressClient";
import { type AddressData } from "services/address/addressTypes";
import { type CompanyStore } from "services/company/companyStore";

export abstract class CompanyAddressStoreBase {
  private _address?: AddressData;

  protected constructor(
    protected readonly _companyStore: CompanyStore,
    protected readonly _addressClient: AddressClient,
  ) {
    makeObservable(this, {
      _address: observable,
    } as any);
  }

  get address(): undefined | AddressData {
    return this._address;
  }

  set address(value: undefined | AddressData) {
    this._address = value;
  }

  async init(): Promise<void> {
    const company = this._companyStore.company;
    if (company) {
      this.address = await this._addressClient.getAddress(AddressKey.Company, company.id);
    }
  }
}
