import { type ApiClient } from "@anna-money/anna-web-lib";

import {
  type CompanyCountryUpdateData,
  type CompanyCreateData,
  CompanyData,
  CompanyListData,
  type CompanyNameUpdateData,
  type CompanyPoaUpdateData,
  type CompanyPricingPlanUpdateData,
  type CompanySharesUpdateData,
  type CompanyUpdateData,
  type CompanyUseVirtualOfficeUpdateData,
} from "services/company/companyTypes";

export class CompanyClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async getCompany(): Promise<CompanyData | undefined> {
    const response = await this._apiClient.get("api/formations/companies");
    const companies = response.getData(CompanyListData);

    return companies.companies.length ? companies.companies[0] : undefined;
  }

  async updateCompany(
    companyId: string,
    data:
      | CompanyUpdateData
      | CompanyNameUpdateData
      | CompanyPoaUpdateData
      | CompanyUseVirtualOfficeUpdateData
      | CompanyCountryUpdateData
      | CompanySharesUpdateData
      | CompanyPricingPlanUpdateData,
  ): Promise<CompanyData> {
    const response = await this._apiClient.post(`api/formations/companies/${companyId}`, data);

    return response.getData(CompanyData);
  }

  async createCompany(data: CompanyCreateData): Promise<CompanyData> {
    const response = await this._apiClient.post("api/formations/companies", data);
    return response.getData(CompanyData);
  }
}
