export enum PricingPlan {
  JustRegister = "just_register",
  FullFormation = "full_formation", // deprecated
  FullFormation19 = "full_formation_19",
  AllInOne = "all_on_one",
  // AU
  EasyCompany = "easy_company",
  AnnaOne = "anna_one_yearly",
}

export type PricingPlanGb =
  | PricingPlan.JustRegister
  | PricingPlan.FullFormation
  | PricingPlan.FullFormation19
  | PricingPlan.AllInOne;

export type PricingPlanAu = PricingPlan.EasyCompany | PricingPlan.AnnaOne;

export interface PricingPlanViewInfo {
  title: string;
  price: string;
  oldPrice?: string;
  remark: string;
}
