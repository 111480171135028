import { type AddressVerificationClient } from "services/addressVerification/addressVerificationClient";

export class AddressVerificationStore {
  private readonly _addressVerificationClient: AddressVerificationClient;

  constructor(addressVerificationClient: AddressVerificationClient) {
    this._addressVerificationClient = addressVerificationClient;
  }

  async needPoaForAddress(addressId: string): Promise<boolean> {
    try {
      const result = await this._addressVerificationClient.getAddressVerification(addressId);
      return result.needPoa;
    } catch (e) {
      console.error(`Couldn't load verification results for address ${addressId}`);
      return true;
    }
  }
}
