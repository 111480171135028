import { type FC, type ReactNode, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useServices } from "appServices";
import { Page } from "pages";
import { FlowType } from "services/user/userTypes";

interface UserFlowGuardProps {
  children: ReactNode;
}

export const UserFlowGuard: FC<UserFlowGuardProps> = ({ children }) => {
  const {
    userStore: { user, status },
    analyticsManager,
    config,
  } = useServices();
  const navigate = useNavigate();

  useEffect(() => {
    analyticsManager.event("user-state", {
      canProceed: status.canProceed,
      companies: status.companies.join(),
      blockedByOnboarding: status.blockedByOnboarding,
    });

    if (!status.canProceed) {
      navigate(Page.ExistedCustomer);
      return;
    }

    if (config.isAU) {
      switch (user.currentFlowType) {
        case FlowType.Acn:
          navigate(Page.Acn);
          break;
      }
    }
  }, [status.canProceed, user.currentFlowType]);

  return <>{children}</>;
};
