import { AnnaError } from "@anna-money/anna-web-lib";
import { runInAction } from "mobx";

import { returnQuestionParam } from "auth/authPages";
import { isSomeEnum } from "helpers/enum";
import { type ConsentFormValues } from "helpers/forms/consentTypes";
import { type DirectorFormValues } from "helpers/forms/directorTypes";
import { type CompanyFormValues } from "helpers/forms/gb/companyTypes";
import { type ParticularsFormValues } from "helpers/forms/particularsTypes";
import { type AnalyticsManager } from "services/analytics/analyticsManager";
import type { BusinessActivitiesStore } from "services/businessActivities/businessActivitiesStore";
import { type CompanyStore } from "services/company/companyStore";
import { type CompanyAddressStore } from "services/companyAddress/gb/companyAddressStore";
import type { CompanyBusinessActivitiesStore } from "services/companyBusinessActivities/companyBusinessActivitiesStore";
import { type CompanyProductsStore } from "services/companyProducts/companyProductsStore";
import { type ConsentStore } from "services/consent/consentStore";
import { type DirectorEntity, type DirectorStore } from "services/director/directorStore";
import { type FormationExpressStore } from "services/formationExpress/formationExpressStore";
import { type IncorporationStore } from "services/incorporation/incorporationStore";
import { IncorporationStatus } from "services/incorporation/incorporationTypes";
import { type IssuesStore } from "services/issues/issuesStore";
import { type PaymentMethodsStore } from "services/paymentMethods/paymentMethodsStore";
import { type PricingPlanStore } from "services/pricingPlan/gb/pricingPlanStore";
import { RegisterFormStoreBase } from "services/registerForm/registerFormStoreBase";
import { type CompanyStepEntity, type CompanyStepStore } from "services/stepStore/gb/companyStepStore";
import { type ParticularsStepStore } from "services/stepStore/gb/particularsStepStore";
import { type SumsubApplicationStore } from "services/sumsub/sumsubApplicationStore";
import type { UserStore } from "services/user/userStore";
import { FlowType } from "services/user/userTypes";
import { type BackendFormValidationError } from "types/form";
import { IncoroporationFormStep } from "./constants";
import { type IncorporationRegisterFormStepsStore } from "./incorporationRegisterFormStepsStore";

export class IncorporationRegisterFormStore extends RegisterFormStoreBase<IncoroporationFormStep> {
  protected _flowType: FlowType = FlowType.Incorporation;

  constructor(
    private readonly _consentStore: ConsentStore,
    private readonly _directorStore: DirectorStore,
    private readonly _companyStore: CompanyStore,
    private readonly _companyAddressStore: CompanyAddressStore,
    private readonly _businessActivitiesStore: BusinessActivitiesStore,
    private readonly _companyBusinessActivitiesStore: CompanyBusinessActivitiesStore,
    private readonly _companyProductsStore: CompanyProductsStore,
    private readonly _particularsStepStore: ParticularsStepStore,
    private readonly _paymentMethodsStore: PaymentMethodsStore,
    private readonly _sumsubStore: SumsubApplicationStore,
    private readonly _formationExpressStore: FormationExpressStore,
    protected readonly _userStore: UserStore,
    protected readonly _analyticsManager: AnalyticsManager,
    protected readonly _registerFormStepsStore: IncorporationRegisterFormStepsStore,
    protected readonly _incorporationStore: IncorporationStore,
    protected readonly _companyIssuesStore: IssuesStore,
    protected readonly _pricingPlanStore: PricingPlanStore,
    protected readonly _companyStepStore: CompanyStepStore,
  ) {
    super(_registerFormStepsStore, _userStore, _incorporationStore, _companyIssuesStore, _analyticsManager);
  }

  async init(): Promise<void> {
    await super.init();

    try {
      // it's important to have company store ready first because we need companyId
      await this._companyStore.init();

      await Promise.all([
        this._consentStore.init(),
        this._companyAddressStore.init(),
        this._businessActivitiesStore.init(),
        this._companyBusinessActivitiesStore.init(),
        this._companyProductsStore.init(),
        this._pricingPlanStore.init(),
        this._directorStore.init(),
        this._sumsubStore.init(),
        this._companyIssuesStore.init(),
        this._incorporationStore.init(),
        this._paymentMethodsStore.init(),
        this._formationExpressStore.init(),
      ]);

      runInAction(() => {
        this.setStep();

        this.state = "done";
      });
    } catch (error: unknown) {
      this.state = "error";

      this._analyticsManager.event("load-form-error");

      throw new AnnaError(`Form couldn‘t load with the error: ${(error as Error).toString()}`);
    }
  }

  setStep(): void {
    const { company } = this._companyStore;
    const { address } = this._companyAddressStore;
    const { mainDirector } = this._directorStore;
    const { sumsubApplication } = this._sumsubStore;
    const { incorporation } = this._incorporationStore;

    if (incorporation?.created) {
      const isRejectedButAllIssuesResolved =
        incorporation?.status === IncorporationStatus.Rejected && !this._companyIssuesStore.hasIssues();
      this._registerFormStepsStore.setStep(
        isRejectedButAllIssuesResolved ? IncoroporationFormStep.CONFIRM : IncoroporationFormStep.COMPLETE,
      );
      return;
    }

    // fallback for the old customer's without pricing plan
    if (company && !company.pricingPlan) {
      this._registerFormStepsStore.setStep(IncoroporationFormStep.COMPANY);
      return;
    }

    if (this._needToOpenStep(IncoroporationFormStep.CHARGE)) {
      this._registerFormStepsStore.setStep(IncoroporationFormStep.CHARGE);
      return;
    }

    if (this._needToOpenStep(IncoroporationFormStep.BASKET)) {
      this._registerFormStepsStore.setStep(IncoroporationFormStep.BASKET);
      return;
    }

    if (sumsubApplication) {
      this._registerFormStepsStore.setStep(IncoroporationFormStep.IDENTITY);
      return;
    }

    if (mainDirector) {
      this._registerFormStepsStore.setStep(IncoroporationFormStep.DIRECTOR);
      return;
    }

    // we created constants during sign-up
    // having address means customer filled something
    // other things - start from 1 step (intro)
    if (address) {
      this._registerFormStepsStore.setStep(IncoroporationFormStep.COMPANY);
      return;
    }

    this._registerFormStepsStore.setStep(IncoroporationFormStep.INTRO);
  }

  protected async _updateStep(data: unknown, entity?: unknown): Promise<BackendFormValidationError> {
    switch (this._registerFormStepsStore.activeStep?.type) {
      case IncoroporationFormStep.CONSENT:
        return await this._consentStore.updateConsents(data as ConsentFormValues);
      case IncoroporationFormStep.COMPANY:
        return await this._companyStepStore.updateEntity(data as CompanyFormValues, entity as CompanyStepEntity);
      case IncoroporationFormStep.DIRECTOR:
        return await this._directorStore.updateMainDirector(data as DirectorFormValues, entity as DirectorEntity);
      case IncoroporationFormStep.SHARES:
        return await this._particularsStepStore.updateShares(data as ParticularsFormValues);
      case IncoroporationFormStep.CHARGE:
        return await this.createIncorporation();
    }
  }

  private _needToOpenStep(step: IncoroporationFormStep): boolean {
    const searchParams = new URLSearchParams(window.location.search);
    const queryStringReturnStep = (searchParams.get(returnQuestionParam) ?? "").split("_")[0];
    const isWizardStep = isSomeEnum(IncoroporationFormStep);
    return isWizardStep(queryStringReturnStep) && queryStringReturnStep === step;
  }
}
