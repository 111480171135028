import { makeObservable, observable } from "mobx";
import { type AnalyticsManager } from "services/analytics/analyticsManager";
import { type IncorporationStore } from "services/incorporation/incorporationStore";
import { IncorporationStatus } from "services/incorporation/incorporationTypes";
import { type IssuesStore } from "services/issues/issuesStore";
import type { UserStore } from "services/user/userStore";
import type { FlowType } from "services/user/userTypes";
import { type BackendFormValidationError } from "types/form";
import { type LoadableState } from "types/loadableState";
import { type RegisterFormStep, type RegisterFormStepsStoreBase } from "./registerFormStepsStoreBase";

export type RegisterFormStoreState = LoadableState<"done">;

export abstract class RegisterFormStoreBase<T> {
  protected abstract _flowType: FlowType;
  private _state: RegisterFormStoreState = "loading";

  protected set state(value: RegisterFormStoreState) {
    this._state = value;
  }

  get state(): RegisterFormStoreState {
    return this._state;
  }

  get activeStep(): RegisterFormStep<T> | undefined {
    return this._stepsStore.activeStep;
  }

  protected constructor(
    protected readonly _stepsStore: RegisterFormStepsStoreBase<T>,
    protected readonly _userStore: UserStore,
    protected readonly _incorporationStore: IncorporationStore,
    protected readonly _companyIssuesStore: IssuesStore,
    protected readonly _analyticsManager: AnalyticsManager,
  ) {
    makeObservable(this, {
      _state: observable,
    } as any);
  }

  async init(): Promise<void> {
    if (this._userStore.user.currentFlowType !== this._flowType) {
      await this._userStore.updateCurrentFlow(this._flowType);
    }
  }

  abstract setStep(): void;

  protected abstract _updateStep(data: unknown, entity?: unknown): Promise<BackendFormValidationError>;

  async updateStep(data: unknown, entity?: unknown): Promise<BackendFormValidationError> {
    const error = await this._updateStep(data, entity);

    if (error) {
      return error;
    }

    await this._stepsStore.goNextQuestion();
  }

  showAlert(): boolean {
    const incorporation = this._incorporationStore.incorporation;
    const issues = this._companyIssuesStore.issues;

    return incorporation?.status === IncorporationStatus.Rejected && Boolean(issues?.length);
  }

  protected async createIncorporation(): Promise<BackendFormValidationError> {
    const error = await this._incorporationStore.createIncorporation();

    if (error) {
      return error;
    }

    this._analyticsManager.googleEvent("application_sent");
  }
}
