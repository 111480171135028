import { retry } from "helpers/function";
import { type UserStore } from "services/user/userStore";
import { type AnalyticsClient, AnalyticsClientError } from "./analyticsClient";

export class AnalyticsManager {
  constructor(
    private readonly _userStore: UserStore,
    private readonly _annaAnalytics: AnalyticsClient,
  ) {
    if (Array.isArray(window.webtics)) {
      window.webtics.forEach(([eventType, payload]) => {
        this.event(eventType, payload);
      });
    }
    window.webtics = {
      push: ([eventType, payload]: [string, unknown]) => {
        this.event(eventType, payload);
      },
    };
  }

  event(eventType: string, payload?: unknown): void {
    this._logAnnaEvent(eventType, payload);
  }

  googleEvent(eventType: string): void {
    window.gtag("event", eventType);
  }

  private _logAnnaEvent(eventType: string, payload?: unknown): void {
    // First exception won't be thrown and the next (retried) request won't happen if window is closed
    retry(
      async () => {
        await this._annaAnalytics.postEvent(eventType, this._userStore.user.alias, payload);
      },
      (e) => e instanceof AnalyticsClientError,
      3,
    ).catch((e) => {
      console.error(e);
    });
  }
}
